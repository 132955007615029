import SidebarRouteItem from '@/layout/sidebar/SidebarRouteItem';
import SendOutlined from '@mui/icons-material/SendOutlined';
import { useState } from 'react';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import DialogTitle from '@/components/DialogTitle/DialogTitle';
import { toast } from 'react-toastify';
import { useTranslation } from 'next-i18next';

function SidebarReportIssue() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');

  const copyToClipboard = () => {
    navigator.clipboard.writeText('support@enersem.eu').then(() => {
      toast.success(t('support.clipboard_copy'));
    });
  };

  return (
    <>
      <span onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
        <SidebarRouteItem
          title={t('pathname./support', 'Support')}
          icon={<SendOutlined />}
        />
      </span>
      <Dialog open={open} maxWidth="sm">
        <DialogTitle onClose={() => setOpen(false)}>
          {t('support.title', 'Something to report?')}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {t(
              'support.subtitle',
              'To request support or make a report send an email to the following address:'
            )}
          </Typography>
          {t('support.mail_to', 'Send an email to')}{' '}
          <Button onClick={copyToClipboard}>support@enersem.eu</Button>.
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SidebarReportIssue;
