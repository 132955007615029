import { IconButton, DialogTitle as MuiDialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export default function DialogTitle(props) {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle>
      {children}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
}
